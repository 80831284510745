<!--start dialog template -->
<template>
  <v-row justify="center">
    <v-dialog :value="dialog" persistent max-width="700px" scrollable>
      <v-card>
        <v-toolbar flat color="primary" dark>
          <v-toolbar-title>
            <span class="headline">{{ title }} Salary Scale</span>
          </v-toolbar-title>
        </v-toolbar>
        <v-card-text style="height:400px">
          <v-container>
            <v-form ref="form" v-model="valid">
              <v-row>
                <v-col cols="6" sm="12" md="6" lg="6">
                  <v-text-field
                    label="Title English"
                    v-model="formData.title_en"
                    required
                    :rules="isValid.name"
                  ></v-text-field>
                </v-col>

                <v-col cols="6" sm="12" md="6" lg="6">
                  <v-text-field
                    label="Title Swahili"
                    v-model="formData.title_sw"
                    required
                    :rules="isValid.name"
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="6" sm="12" md="6" lg="6">
                  <v-text-field
                    label="Title English"
                    v-model="formData.short_en"
                    required
                    :rules="isValid.name"
                  ></v-text-field>
                </v-col>

                <v-col cols="6" sm="12" md="6" lg="6">
                  <v-text-field
                    label="Short Name Swahili"
                    v-model="formData.short_sw"
                    required
                    :rules="isValid.name"
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="6" sm="12" md="6" lg="6">
                  <v-text-field
                    label="Salary Scale"
                    v-model="formData.scale"
                    required
                    :rules="isValid.name"
                  ></v-text-field>
                </v-col>

                <v-col cols="6" sm="12" md="6" lg="6">
                  <v-text-field
                    label="Salary Amount"
                    v-model="formData.salary_amount"
                    required
                    :rules="isValid.name"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="12" md="12">
                  <v-autocomplete
                    label="Proffesional Category"
                    :items="taaluma"
                    :item-text="'name'"
                    v-model="formData.professional_category"
                    return-object
                    required
                    v-uppercase
                    :rules="isValid.name"
                  ></v-autocomplete>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="12" md="4" lg="4">
                  <v-radio-group v-model="formData.is_first_appointment" row>
                    <template v-slot:label>
                      <div><strong>Is for First Appointment?</strong></div>
                    </template>
                    <v-radio :value="true">
                      <template v-slot:label>
                        <div>
                          <strong class="success--text">Yes</strong>
                        </div>
                      </template>
                    </v-radio>
                    <v-radio :value="false">
                      <template v-slot:label>
                        <div>
                          <strong class="primary--text">No</strong>
                        </div>
                      </template>
                    </v-radio>
                  </v-radio-group>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>

          <DialogButtons
            :close="close"
            :save="add"
            :loading="loading"
            :valid="valid"
            :title="title"
          />
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<!--end dialog-->

<script>
export default {
  props: [
    "closeDialog",
    "dialog",
    "isEditing",
    "formData",
    "save",
    "loading",
    "taaluma",
  ],
  name: "OptionDialog",
  data: () => ({
    valid: true,
    isValid: {
      name: [
        (v) => !!v || " Name required",
        /// (v) => /.+[^#<>:;,?""*|/]+/.test(v) || 'Invalid Character',
      ],
    },
  }),
  methods: {
    add() {
      let data = { ...this.formData };
      data.code = data.code
        ? data.code
        : data.name
            .trim()
            .split(" ")
            .join("_")
            .toUpperCase();

      this.save(data);
    },

    close() {
      this.closeDialog();
    },
  },
  computed: {
    title() {
      return this.formData.id ? "Update" : "Create";
    },
  },
  mounted() {},
};
</script>
